<template>
  <instance-modal
      :show="show"
      title="Delete Instance"
      buttonLabel="Delete"
      @hide-modal="hideDelete"
      @save-pressed="deleteInstance">
      <p class="content">Ok to delete instance {{ value }}?</p>
  </instance-modal>
</template>
<script>
import { mapActions } from "vuex";
import InstanceModal from "./InstanceModal";

export default {
    props: ["show","value"],
    components: { InstanceModal },
    methods: {
        ...mapActions("inst", ["fetchInstances", "deletePaymentPortalInstanceConfig"]),
        hideDelete() {
            this.$emit("show-updated", false);
        },
        async deleteInstance() {
            await this.deletePaymentPortalInstanceConfig(this.value);
            this.hideDelete();
        },
    }
};
</script>