<template>
  <div class="container">
    <div class="field">
      <label class="checkbox">
        <input type="checkbox" v-model="ic.webLinksEnabled" />
        Form Links Enabled
      </label>
    </div>
    <div class="field">
      <label class="checkbox">
        <input type="checkbox" v-model="ic.documentsEnabled" />
        Documents Enabled
      </label>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">
            Top Nav Button Label
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Button Label"
              v-model="ic.documentsButtonLabel"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <img src="@/assets/upload_docs.png" alt="" />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">
            Document Type Used for Upload
          </label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="ic.uploadDocType">
                <option
                  v-for="myOption in documentTypeList"
                  :value="myOption.type"
                  :key="myOption.id"
                  >{{ myOption.desc }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
    <div class="field">
      <label class="label">
        Document Types Viewable by User
      </label>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label has-text-grey">
            Available Types
          </label>
          <div class="control">
            <div class="select is-fullwidth is-multiple">
              <select multiple size="4" v-model="selectedToAdd">
                <option
                  v-for="myOption in documentTypeListFiltered"
                  :value="myOption"
                  :key="myOption.id"
                  >{{ myOption.desc }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="buttons">
            <button
              class="button is-success  is-light"
              @click="addselectedDocs"
            >
              <span>
                Add
              </span>
              <span class="icon is-small">
                <font-awesome-icon icon="angle-double-right" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label has-text-grey">
            Viewable Types
          </label>
          <div class="control">
            <div class="select is-fullwidth is-multiple">
              <select multiple size="4" v-model="selectedToRemove">
                <option
                  v-for="myOption in viewableDocTypesTemp"
                  :value="myOption"
                  :key="myOption.id"
                  >{{ myOption.desc }}</option
                >
              </select>
            </div>
          </div>
        </div>
        <div class="control"></div>
        <div class="level-left">
          <div class="buttons">
            <button
              class="button is-danger is-light"
              @click="RemoveSelectedDocs"
            >
              <span class="icon is-small">
                <font-awesome-icon icon="angle-double-left" />
              </span>
              <span>
                Remove
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="buttons">
        <button class="button is-success" @click="saveFunction">
          Save
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/Service";
import saveRevert from "@/mixins/negotiatorMixins";
import functions from "@/functions.js";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [saveRevert],
  data() {
    return {
      selectedToAdd: [],
      selectedToRemove: [],
      documentTypeList: [], //all available list
      documentTypeListFiltered: [], //exclude  viewable
      viewableDocTypesTemp: [],
      ic: {
        documentsButtonLabel: null,
        webLinksEnabled: null, //enableFormLinks: null,
        documentsEnabled: null, //enableDocuments: null, //documentsEnabled
        uploadDocType: null,
        viewableDocTypes: []
      }
    };
  },
  computed: {
    ...mapGetters(["negotiatorEnabled"])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        await vm.fetchPaymentPortalInstanceConfig(to.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm, to.params.id);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceNegotiator; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView(to.params.id);
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    ...mapActions("inst", ["fetchInstances"]),
      ...mapActions([
          "fetchPaymentPortalGlobalConfig"
      ]),
    addselectedDocs() {
      let array1 = this.viewableDocTypesTemp.concat(this.selectedToAdd);
      this.viewableDocTypesTemp = [...new Set(array1)];
      this.documentTypeListFiltered = this.documentTypeListFiltered.filter(
        item => !this.selectedToAdd.includes(item)
      );
    },
    RemoveSelectedDocs() {
      let array1 = this.documentTypeListFiltered.concat(this.selectedToRemove);
      this.documentTypeListFiltered = [...new Set(array1)];
      this.viewableDocTypesTemp = this.viewableDocTypesTemp.filter(
        item => !this.selectedToRemove.includes(item)
      );
    },
    async loadDocTypes() {
      this.documentTypeList = await api.getDocTypes();
    },
    saveFunction() {
      this.ic.viewableDocTypes = JSON.stringify(
        this.viewableDocTypesTemp.map(item => item.type)
      )
        .replace(/"/g, "")
        .replace("[", "")
        .replace("]", "");
      this.save();
    },
    loadView(id) {
      this.ic.instanceId = id;
      this.ic.documentsButtonLabel = this.$store.state.instanceConfig.documentsButtonLabel;
      this.ic.webLinksEnabled = this.$store.state.instanceConfig.webLinksEnabled;
      this.ic.documentsEnabled = this.$store.state.instanceConfig.documentsEnabled;
      this.ic.uploadDocType = this.$store.state.instanceConfig.uploadDocType;
      if (this.$store.state.instanceConfig.viewableDocTypes != null) {
        let temporaryDocTypes = this.$store.state.instanceConfig.viewableDocTypes.split(
          ","
        );
        temporaryDocTypes.sort();
        this.ic.viewableDocTypes = functions.arrayMatch(
          this.documentTypeList,
          temporaryDocTypes
        );
        this.viewableDocTypesTemp = this.ic.viewableDocTypes; //
        this.documentTypeListFiltered = this.documentTypeList.filter(
          item => !this.ic.viewableDocTypes.includes(item)
        );
      } else {
        this.documentTypeListFiltered = this.documentTypeList;
      }
    }
  },
  mounted() {
    this.loadDocTypes();
  }
};
</script>
