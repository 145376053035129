<template>
  <div class="page-wrapper">
    <nav class="navbar has-shadow">
      <div class="navbar-brand">
        <h1 class="navbar-item"><img src="../assets/css_logo_32x32.png" /></h1>
        <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{ 'is-active': activated }"
        @click="activated = !activated"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': activated }">
        <div class="navbar-start">
          <div class="navbar-item">
            <small>CSS Web Admin</small>
          </div>
        </div>
        <div class="navbar-end">
          <a href="#" class="navbar-item" @click="logout">Sign Out</a>
        </div>
      </div>
    </nav>

    <section class="section content-wrapper">
      <div class="columns">
        <div class="column is-4-tablet is-3-desktop is-2-widescreen">
          <nav class="menu">
            <p class="menu-label">
              Payment Portal
            </p>
            <ul class="menu-list">
              <li>
                <router-link
                    :to="{ name: 'instances' }"
                    :class="{ 'is-active': $route.name == 'instances' }"
                >
                  Instances
                </router-link>
                <ul>
                  <li v-for="inst in instances" :key="inst">
                    <router-link
                      :to="{ name: 'instance.main', params: { id: inst } }"
                      :class="{ 'is-active': $route.name == 'instance.main' && $route.params.id == inst }"
                    >{{ inst }}</router-link
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link :to="{ name: 'company' }">Company</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'home' }"
                  :class="{ 'is-active': $route.name == 'home' }"
                  >
                  General Information
                </router-link
                >
              </li>
              <li>
                <router-link
                    :to="{ name: 'portal' }"
                    :class="{ 'is-active': $route.name == 'portal' }"
                >
                  Installation
                </router-link>
              </li>
              <p class="menu-label">
                Hosted Payment Page
              </p>
              <li>
                <router-link :to="{ name: 'hpp' }">Config</router-link>
              </li>
              <p class="menu-label">
                Secure Link
              </p>
              <li>
                <router-link
                    :to="{ name: 'secureLink' }"
                    :class="{ 'is-active': $route.name == 'secureLink' }"
                >
                  Config
                </router-link>
              </li>
              <p class="menu-label">
                Receipt Comms
              </p>
              <li>
                <router-link
                    :to="{ name: 'receiptComms' }"
                    :class="{ 'is-active': $route.name == 'receiptComms' }"
                >
                  Config
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="column">
          <notification />
          <slot></slot>
        </div>
      </div>
    </section>
    <footer class="footer has-text-centered">
      <p class="has-text-white is-size-5">877-277-4621 | cssimpact.com</p>
      <p class="has-text-white">
        <small>CSS, Inc. – Financial Ecosystems - All Rights Reserved</small>
      </p>
    </footer>
  </div>
</template>
<script>
import Notification from "@/components/Notification.vue";
import { mapState } from "vuex";

export default {
  components: { Notification },
  computed: {
    ...mapState("inst", ["instances"])
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    }
  },
  data() {
    return {
      activated: false
    }
  }
};
</script>
<style lang="scss">
.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}
</style>
