var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card field"},[_c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.titleLabel))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":_vm.passedRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label1))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"input",attrs:{"type":"text","name":_vm.label1},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":_vm.passedRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label2))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"input",attrs:{"type":"text","name":_vm.label2},domProps:{"value":(_vm.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":_vm.passedRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label3))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.button),expression:"button"}],staticClass:"input",attrs:{"type":"text","name":_vm.label3},domProps:{"value":(_vm.button)},on:{"input":function($event){if($event.target.composing){ return; }_vm.button=$event.target.value}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":_vm.getImage.call(),"alt":_vm.imgAlt}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }