<template>
    <div class="tabs">
      <ul>
        <li :class="{ 'is-active': $route.name == 'instance.main' }">
          <router-link :to="{ name: 'instance.main' }">Main</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'instance.theme' }">
          <router-link :to="{ name: 'instance.theme' }">Theme</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'instance.documents' }">
          <router-link :to="{ name: 'instance.documents' }"
            >Documents</router-link
          >
        </li>
        <li :class="{ 'is-active': $route.name == 'instance.overTime' }" v-if="payOverTimeEnabled">
          <router-link :to="{ name: 'instance.overTime' }"
            >Over_Time</router-link
          >
        </li>
        <li :class="{ 'is-active': $route.name == 'makePayment' }"
          v-if="negotiatorEnabled">
          <router-link :to="{ name: 'makePayment' }">Negotiator</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'instance.login' }">
          <router-link :to="{ name: 'instance.login' }">Login</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'instance.guest' }"
          v-if="guestEnabled">
          <router-link :to="{ name: 'instance.guest' }">Guest</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'instance.mfa' }">
          <router-link :to="{ name: 'instance.mfa' }">MFA</router-link>
        </li>
      </ul>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    computed: {
      ...mapGetters(["negotiatorEnabled", "guestEnabled","payOverTimeEnabled"])
    }
};
</script>