var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Required Row ACCOUNT Label")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rowAccount),expression:"rowAccount"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. ACCOUNT","name":"Row ACCOUNT"},domProps:{"value":(_vm.rowAccount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.rowAccount=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Required Row PMT_DISPOSITION Label")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rowPmtDisposition),expression:"rowPmtDisposition"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. PMT_DISPOSITION","name":"Row PMT_DISPOSITION"},domProps:{"value":(_vm.rowPmtDisposition)},on:{"input":function($event){if($event.target.composing){ return; }_vm.rowPmtDisposition=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Required Row DUE Label")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rowDue),expression:"rowDue"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. DUE","name":"Row DUE"},domProps:{"value":(_vm.rowDue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.rowDue=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Required Row Pay Over Time DUE Label")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rowPOTDue),expression:"rowPOTDue"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. POT_DUE","name":"Row Pay Over Time DUE"},domProps:{"value":(_vm.rowPOTDue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.rowPOTDue=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Required Row DEMIDTYNO Label")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rowDemIdtyNo),expression:"rowDemIdtyNo"}],staticClass:"input",attrs:{"type":"text","placeholder":"e.g. DEMIDTYNO","name":"Row DEMIDTYNO"},domProps:{"value":(_vm.rowDemIdtyNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.rowDemIdtyNo=$event.target.value}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }