<template>
  <base-layout>
    <h1 class="title">Instance Config</h1>

    <div class="field">
      <div class="buttons">
        <button class="button is-primary" @click="setModalFlag(true)">Add</button>
        <button class="button" @click="setCloneModalFlag(true)">Clone</button>
      </div>
    </div>

    <instances-table :instances="instances" @delete-instance="showDelete" @rename-instance="showRename" />

    <add-instance 
      :show="modalFlag" 
      @show-updated="setModalFlag" 
      />

    <clone-instance 
      :show="cloneModalFlag" 
      @show-updated="setCloneModalFlag" 
      :instances="instances" 
      />

    <delete-instance 
      :show="deleteModalFlag"
      :value="toDelete"
      @show-updated="setDeleteModalFlag" />

    <rename-instance
      :show="renameModalFlag"
      :value="toRename" 
      @show-updated="setRenameModalFlag" />

  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { mapState, mapActions } from "vuex";
import InstancesTable from "./InstancesTable.vue";
import CloneInstance from "./CloneInstance.vue";
import AddInstance from "./AddInstance.vue";
import DeleteInstance from "./DeleteInstance.vue";
import RenameInstance from "./RenameInstance.vue";

export default {
  components: { 
    BaseLayout, 
    InstancesTable,
    CloneInstance,
    AddInstance,
    DeleteInstance,
    RenameInstance
  },
  computed: {
    ...mapState("inst", ["instances"])
  },
  data() {
    return {
      modalFlag: false,
      deleteModalFlag: false,
      renameModalFlag: false,
      newInstance: null,
      toDelete: null,
      toRename: null,
      cloneModalFlag: false
    };
  },
  methods: {
    ...mapActions("inst", ["fetchInstances"]),
    showDelete(instanceId) {
      this.toDelete = instanceId;
      this.setDeleteModalFlag(true);
    },
    showRename(instanceId) {
      this.toRename = instanceId;
      this.setRenameModalFlag(true);
    },
    setCloneModalFlag(val) {
      this.cloneModalFlag = val;
    },
    setModalFlag(val) {
      this.modalFlag = val;
    },
    setDeleteModalFlag(val) {
      this.deleteModalFlag = val;
    },
    setRenameModalFlag(val) {
      this.renameModalFlag = val;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchInstances());
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
