<template>
  <base-layout>
    <h1 class="title">General Information</h1>
    <validation-observer v-slot="{ invalid }">
      <validation-provider
        tag="div"
        v-slot="{ errors, failed }"
        rules="max:1000"
        class="field"
      >
        <div class="control">
          <label class="label">General Contact Message For Help</label>
          <textarea
            class="textarea"
            v-model="gc.generalContactMessage"
          ></textarea>
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <home-portal-middle class="mb-3" :value="{ 'rowAccount': gc.rowAccount, 
                              'rowPmtDisposition': gc.rowPmtDisposition, 
                              'rowDue': gc.rowDue,
                              'rowPOTDue': gc.rowPOTDue,
                              'rowDemIdtyNo': gc.rowDemIdtyNo
                               }"
                    @home-portal-middle-updated="updateHomePortalMiddle" />

      <validation-provider
          tag="div"
          v-slot="{ errors, failed }"
          rules="max:255"
          class="field"
      >
        <div class="control">
          <label class="label">LogRocket App Id</label>
          <input
              class="input"
              v-model="gc.logRocketAppId"
              name="logRocketAppId"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>

      <div class="field">
        <div class="buttons">
          <button
            :disabled="invalid"
            class="button is-success"
            @click="savePaymentPortalGlobalConfig"
          >
            Save
          </button>
          <button class="button is-light" @click="revert">
            Revert
          </button>
        </div>
        <progress-bar />
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { max } from "vee-validate/dist/rules";
import ProgressBar from "@/components/ProgressBar.vue";
import { mapActions } from "vuex";
import HomePortalMiddle from "./HomePortalMiddle.vue";

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    BaseLayout,
    ValidationProvider,
    ValidationObserver,
    ProgressBar,
    HomePortalMiddle
  },
  data() {
    return {
      gc: {
        generalContactMessage: null,
        rowAccount: null,
        rowPmtDisposition: null,
        rowDue: null,
        rowPOTDue: null,
        rowDemIdtyNo: null,
        logRocketAppId: null
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        vm.$store.dispatch("inst/fetchInstances");
        vm.loadView(vm);
      } catch (e) {
        vm.$store.commit("SHOW_NOTIFICATION", `Error loading Home; ${e}`);
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  methods: {
    ...mapActions(["fetchPaymentPortalGlobalConfig"]),
    loadView(vm) {
      vm.gc.generalContactMessage =
        vm.$store.state.globalConfig.generalContactMessage;
      this.gc.rowAccount = this.$store.state.globalConfig.rowAccount;
      this.gc.rowPmtDisposition = this.$store.state.globalConfig.rowPmtDisposition;
      this.gc.rowDue = this.$store.state.globalConfig.rowDue;
      this.gc.rowPOTDue = this.$store.state.globalConfig.rowPOTDue;
      this.gc.rowDemIdtyNo = this.$store.state.globalConfig.rowDemIdtyNo;
      this.gc.logRocketAppId = this.$store.state.globalConfig.logRocketAppId;
    },
    async savePaymentPortalGlobalConfig() {
      await this.$store.dispatch("savePaymentPortalGlobalConfig", this.gc);
      window.scrollTo(0, 0);
    },
    async revert() {
      await this.fetchPaymentPortalGlobalConfig();
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Global config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    },
    updateHomePortalMiddle(payload) {
      this.gc.rowAccount = payload.rowAccount;
      this.gc.rowPmtDisposition = payload.rowPmtDisposition;
      this.gc.rowDue = payload.rowDue;
      this.gc.rowPOTDue = payload.rowPOTDue;
      this.gc.rowDemIdtyNo = payload.rowDemIdtyNo;
    }
  }
};
</script>
<style lang="scss">
.clob {
  white-space: pre-wrap;
}
</style>
