<template>
  <base-layout>
    <h1 class="title">Instance Config - {{ $route.params.id }}</h1>
    <instance-tabs />
    <div class="field">
      <progress-bar />
    </div>
    <router-view />
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import InstanceTabs from "./InstanceTabs.vue";

export default {
  components: {
    BaseLayout,
    ProgressBar,
    InstanceTabs
  }
};
</script>
