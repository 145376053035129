<template>
  <validation-observer v-slot="{ invalid }" class="container">
      <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="required"
    >
      <div class="control">
        <label class="label">Instance Type</label>
        <div class="select">
          <select v-model="ic.type" name="Type">
            <option :value="null">Please select a type</option>
            <option value="CONSUMER">Consumer</option>
            <option value="CASHIER">Cashier</option>
          </select>
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label">Secondary Banner</label>
        <input
          type="text"
          class="input"
          placeholder="e.g. Extra Company Info"
          v-model="ic.secondaryBanner"
          name="Secondary Banner"
        />
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label">Document Title</label>
        <input
          type="text"
          class="input"
          placeholder="e.g. My Company Inc."
          v-model="ic.documentTitle"
          name="Document Title"
        />
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label">Header</label>
        <input
          type="text"
          class="input"
          placeholder="e.g. My Company Inc."
          v-model="ic.header"
          name="Header"
        />
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label">Footer Line 1 (strong)</label>
        <input
          type="text"
          class="input"
          placeholder="e.g. https://mycompanyinc.com"
          v-model="ic.footer"
          name="Footer 1"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label">Footer Line 2 (small)</label>
        <input
          type="text"
          class="input"
          placeholder="e.g. (c) 2020 My Company Inc."
          v-model="ic.footer2"
          name="Footer 2"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label">Login Message</label>
        <textarea
          class="textarea"
          placeholder="e.g. By using this SITE you agree to its TERMS OF SERVICE"
          v-model="ic.loginMessage"
          name="Login Message"
        ></textarea>
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label is-inline">Logo URL (top left navbar)</label>
        &nbsp;<small>32x32 pixel .jpg, .gif, or .png</small>
        <input
          class="input"
          type="text"
          :value="fileName(ic.logoURL)"
          name="Logo URL"
          readonly
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <file-upload
      inputName="file_logoURL"
      inputRef="file_logoURL"
      @fileUploaded="updateLogoURL"
    />
    <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:255"
    >
      <div class="control">
        <label class="label">Alternate Logo Link</label>
        <input
            type="text"
            class="input"
            placeholder="e.g. https://www.example.com/external-link"
            v-model="ic.logoHREF"
            name="Alt Logo Link"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label is-inline"
          >Splash URL (appears on login screen)</label
        >
        &nbsp;<small>256x256 pixel .jpg, .gif, or .png</small>
        <input
          class="input"
          type="text"
          :value="fileName(ic.splashURL)"
          name="Splash URL"
          readonly
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <file-upload
      inputName="file_splashURL"
      inputRef="file_splashURL"
      @fileUploaded="updateSplashURL"
    />

    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:255"
    >
      <div class="control">
        <label class="label is-inline">Favicon URL</label>
        &nbsp;<small>16x16 pixel .ico, .jpg, .gif, or .png</small>
        <input
          class="input"
          type="text"
          :value="fileName(ic.faviconURL)"
          name="Favicon URL"
          readonly
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <file-upload
      inputName="file_faviconURL"
      inputRef="file_faviconURL"
      @fileUploaded="updateFaviconURL"
    />

    <div class="field">
      <div class="control">
        <label class="label">Terms And Conditions</label>
        <textarea
          class="textarea clob"
          v-model="ic.termsAndConditions"
        ></textarea>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <label class="label">Privacy Policy</label>
        <textarea class="textarea clob" v-model="ic.privacyPolicy"></textarea>
      </div>
    </div>

    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="required"
    >
      <div class="control">
        <label class="label"
          >Dem Id Used When Updating Payment Arrangements*</label
        >
        <input
          type="text"
          class="input"
          v-model="ic.ppaDemId"
          name="PPA Dem Id"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <div class="field">
      <div class="control">
        <label class="label">General Contact Message For Help</label>
        <textarea
          class="textarea"
          v-model="ic.generalContactMessage"
        ></textarea>
      </div>
    </div>
    <div class="field">
      <SODSelect
        v-model="ic.regSODCode"
        :strategies="porregStrategies"
        controlLabel="Registration Form SOD*"
        nullValueLabel="Select Registration Workflow SOD"
      />
    </div>
    <option-card
      :p1="ic.payOptionTitle"
      :p2="ic.payOptionContent"
      :p3="ic.payOptionButton"
      emitName="custom-pay-option-updated"
      label1="Option Title*"
      label2="Option Content*"
      label3="Option Button*"
      titleLabel="Custom Pay In Full Option"
      :passedRules="{ required: true, max: 255 }"
      imgSrc="@/assets/ppay_option_guide.png"
      imgAlt="Pay In Full Option Guide"
      @custom-pay-option-updated="updateCustomPayInFullOption"
    />
    <option-card
        :p1="ic.oneOptionTitle"
        :p2="ic.oneOptionContent"
        :p3="ic.oneOptionButton"
        emitName="custom-pay-option-updated"
        label1="Option Title*"
        label2="Option Content*"
        label3="Option Button*"
        titleLabel="Custom One-Time Pay Option"
        :passedRules="{ required: true, max: 255 }"
        imgSrc="@/assets/ppay_option_one_guide.png"
        imgAlt="One-Time Pay Option Guide"
        @custom-pay-option-updated="updateCustomOneTimePayOption"
    />
    <option-card
        :p1="ic.updOptionTitle"
        :p2="ic.updOptionContent"
        :p3="ic.updOptionButton"
        emitName="custom-pay-option-updated"
        label1="Option Title*"
        label2="Option Content*"
        label3="Option Button*"
        titleLabel="Custom Update Payment Info Option"
        :passedRules="{ required: true, max: 255 }"
        imgSrc="@/assets/ppay_option_upd_guide.png"
        imgAlt="Update Payment Information Option Guide"
        @custom-pay-option-updated="updateCustomUpdatePaymentInfoOption"
    />

    <home-and-header-customizations
      :homeCol1="ic.homeCol1"
      :homeCol2="ic.homeCol2"
      :homeCol3="ic.homeCol3"
      @home-and-header-customizations-updated="
        updateHomeAndHeaderCustomizations
      "
    />

    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="required"
    >
      <div class="control">
        <label class="label"
          >Message Displayed to Customer on Registration Failure*</label
        >
        <input
          type="text"
          class="input"
          v-model="ic.regFailureMessage"
          name="Registration Failure Message"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>

    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="required"
    >
      <div class="control">
        <label class="label"
          >Message On Login Prompting User Registration*</label
        >
        <input
          type="text"
          class="input"
          v-model="ic.regTextMessage"
          name="Registration Text Message"
        />
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
    <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="numeric"
    >
      <div class="control">
        <label class="label">Minimum Total Due</label>
        <input
            type="text"
            class="input"
            v-model="ic.minTotalDue"
            name="Minimum Total Due"
        />
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
          <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="ic.termsAndConditionsEnabled" />
          Terms and Conditions Enabled
        </label>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="ic.stateDeclarationsEnabled" />
          State Declarations Enabled
        </label>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="ic.privacyPolicyEnabled" />
          Privacy Policy Enabled
        </label>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="ic.contactUsEnabled" />
          Contact Us Enabled
        </label>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="ic.changePasswordEnabled" />
          Change Password Enabled
        </label>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox" v-model="ic.signOutEnabled" />
          Sign Out Enabled
        </label>
      </div>

    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
    <div class="field">
      <progress-bar />
    </div>
  </validation-observer>
</template>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import FileUpload from "@/components/FileUpload.vue";
import SODSelect from "@/components/SODSelect.vue";
import OptionCard from "@/components/instance/OptionCard.vue";
import HomeAndHeaderCustomizations from "@/components/instance/HomeAndHeaderCustomizations.vue";
import { mapState, mapActions } from "vuex";
import {numeric} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("numeric", {
  ...numeric,
  message: "{_field_} must be a whole number."
});

export default {
  components: {
    ProgressBar,
    FileUpload,
    SODSelect,
    OptionCard,
    HomeAndHeaderCustomizations
  },
  data() {
    return {
      ic: {
        type: null,
        instanceId: null,
        documentTitle: null,
        header: null,
        footer: null,
        footer2: null,
        helpMessage: null,
        logoURL: null,
        logoHREF: null,
        splashURL: null,
        faviconURL: null,
        imageDir: null,
        loginMessage: null,
        termsAndConditions: null,
        privacyPolicy: null,
        ppaDemId: null,
        generalContactMessage: null,
        regSODCode: null,
        payOptionTitle: null,
        payOptionContent: null,
        payOptionButton: null,
        oneOptionTitle: null,
        oneOptionContent: null,
        oneOptionButton: null,
        updOptionTitle: null,
        updOptionContent: null,
        updOptionButton: null,
        homeCol1: null,
        homeCol2: null,
        homeCol3: null,
        regFailureMessage: null,
        regTextMessage: null,
        secondaryBanner: null,
        termsAndConditionsEnabled: false,
        stateDeclarationsEnabled: false,
        privacyPolicyEnabled: false,
        contactUsEnabled: false,
        changePasswordEnabled: false,
        signOutEnabled: false,
        minTotalDue: null
      }
    };
  },
  computed: {
    ...mapState(["porregStrategies"])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        await vm.fetchPORREGStrategies();
        await vm.fetchPaymentPortalInstanceConfig(to.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm, to.params.id);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceMain; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView(to.params.id);
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    loadView(id) {
      this.ic.instanceId = id;
      this.ic.type = this.$store.state.instanceConfig.type;
      this.ic.documentTitle = this.$store.state.instanceConfig.documentTitle;
      this.ic.header = this.$store.state.instanceConfig.header;
      this.ic.footer = this.$store.state.instanceConfig.footer;
      this.ic.footer2 = this.$store.state.instanceConfig.footer2;
      this.ic.helpMessage = this.$store.state.instanceConfig.helpMessage;
      this.ic.logoURL = this.$store.state.instanceConfig.logoURL;
      this.ic.logoHREF = this.$store.state.instanceConfig.logoHREF;
      this.ic.splashURL = this.$store.state.instanceConfig.splashURL;
      this.ic.faviconURL = this.$store.state.instanceConfig.faviconURL;
      this.ic.imageDir = this.$store.state.instanceConfig.imageDir;
      this.ic.loginMessage = this.$store.state.instanceConfig.loginMessage;
      this.ic.termsAndConditions = this.$store.state.instanceConfig.termsAndConditions;
      this.ic.privacyPolicy = this.$store.state.instanceConfig.privacyPolicy;
      this.ic.ppaDemId = this.$store.state.instanceConfig.ppaDemId;
      this.ic.generalContactMessage = this.$store.state.instanceConfig.generalContactMessage;
      this.ic.regSODCode = this.$store.state.instanceConfig.regSODCode;
      this.ic.payOptionTitle = this.$store.state.instanceConfig.payOptionTitle;
      this.ic.payOptionContent = this.$store.state.instanceConfig.payOptionContent;
      this.ic.payOptionButton = this.$store.state.instanceConfig.payOptionButton;
      this.ic.oneOptionTitle = this.$store.state.instanceConfig.oneOptionTitle;
      this.ic.oneOptionContent = this.$store.state.instanceConfig.oneOptionContent;
      this.ic.oneOptionButton = this.$store.state.instanceConfig.oneOptionButton;
      this.ic.updOptionTitle = this.$store.state.instanceConfig.updOptionTitle;
      this.ic.updOptionContent = this.$store.state.instanceConfig.updOptionContent;
      this.ic.updOptionButton = this.$store.state.instanceConfig.updOptionButton;
      this.ic.homeCol1 = this.$store.state.instanceConfig.homeCol1;
      this.ic.homeCol2 = this.$store.state.instanceConfig.homeCol2;
      this.ic.homeCol3 = this.$store.state.instanceConfig.homeCol3;
      this.ic.regFailureMessage = this.$store.state.instanceConfig.regFailureMessage;
      this.ic.regTextMessage = this.$store.state.instanceConfig.regTextMessage;
      this.ic.secondaryBanner = this.$store.state.instanceConfig.secondaryBanner;
      this.ic.termsAndConditionsEnabled = this.$store.state.instanceConfig.termsAndConditionsEnabled;
      this.ic.stateDeclarationsEnabled = this.$store.state.instanceConfig.stateDeclarationsEnabled;
      this.ic.privacyPolicyEnabled = this.$store.state.instanceConfig.privacyPolicyEnabled;
      this.ic.contactUsEnabled = this.$store.state.instanceConfig.contactUsEnabled;
      this.ic.changePasswordEnabled = this.$store.state.instanceConfig.changePasswordEnabled;
      this.ic.signOutEnabled = this.$store.state.instanceConfig.signOutEnabled;
      this.ic.minTotalDue = this.$store.state.instanceConfig.minTotalDue;
    },
    ...mapActions("inst", ["fetchInstances"]),
    ...mapActions([
      "fetchPaymentPortalGlobalConfig",
      "fetchPORREGStrategies",
      "fetchPaymentPortalInstanceConfig",
      "saveInstanceConfig"
    ]),
    async save() {
      window.scrollTo(0, 0);
      await this.saveInstanceConfig(this.ic);
    },
    async revert() {
      await this.fetchPaymentPortalInstanceConfig(this.$route.params.id);
      this.loadView(this.$route.params.id);
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Instance config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    },
    updateLogoURL(fn) {
      this.ic.logoURL = fn;
    },
    updateSplashURL(fn) {
      this.ic.splashURL = fn;
    },
    updateFaviconURL(fn) {
      this.ic.faviconURL = fn;
    },
    fileName: fn => {
      if (fn) {
        let r = /([^/]+)$/g;
        let matches = fn.match(r);
        if (matches) {
          return matches[0];
        }
      }
      return "";
    },
    updateCustomPayInFullOption(payload) {
      this.ic.payOptionTitle = payload.title;
      this.ic.payOptionContent = payload.content;
      this.ic.payOptionButton = payload.button;
    },
    updateCustomOneTimePayOption(payload) {
      this.ic.oneOptionTitle = payload.title;
      this.ic.oneOptionContent = payload.content;
      this.ic.oneOptionButton = payload.button;
    },
    updateCustomUpdatePaymentInfoOption(payload) {
      this.ic.updOptionTitle = payload.title;
      this.ic.updOptionContent = payload.content;
      this.ic.updOptionButton = payload.button;
    },
    updateHomeAndHeaderCustomizations(payload) {
      this.ic.homeCol1 = payload.hc1;
      this.ic.homeCol2 = payload.hc2;
      this.ic.homeCol3 = payload.hc3;
    }
  }
};
</script>
<style lang="scss">
.clob {
  white-space: pre-wrap;
}
</style>
