<template>
  <instance-modal
      :show="show"
      title="Rename Instance"
      buttonLabel="Rename"
      @hide-modal="hideRename"
      @save-pressed="rename">
      <div class="field">
        <div class="control">
          <label class="label">Rename Instance</label>
          <input type="text" class="input" :value="value" name="Rename Instance" disabled/>
        </div>
      </div>
      <validation-provider class="field" v-slot="{ errors }" rules="required">
        <div class="control">
          <label class="label">To Instance</label>
          <input type="text" class="input" v-model="toInstance" name="To Instance" />
          <small
            class="has-text-danger"
            v-show="errors != null && errors.length > 0">
            {{ errors[0] }}
          </small>
        </div>
      </validation-provider>
  </instance-modal>
</template>
<script>
import { mapActions } from "vuex";
import InstanceModal from "./InstanceModal";

export default {
  props: ["show","instances","value"],
  components: { InstanceModal },
  data() {
    return {
      toInstance: null
    }
  },
  methods: {
    ...mapActions("inst", ["renamePaymentPortalInstanceConfig"]),
    hideRename() {
      this.toInstance = null;
      this.$emit("show-updated", false);
    },
    async rename() {
      await this.renamePaymentPortalInstanceConfig({
        instanceId: this.value,
        toId: this.toInstance
      });
      this.hideRename();
    }
  }
};
</script>