<template>
    <table class="table">
      <thead>
        <tr>
          <th>Instance Name</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="inst in instances" :key="inst">
          <td>
            <router-link :to="{ name: 'instance.main', params: { id: inst } }">{{
              inst
            }}</router-link>
          </td>
          <td><a class="delete" @click="handleDelete(inst)"></a></td>
          <td>
            <span class="icon is-small"  @click="handleRename(inst)">
              <font-awesome-icon icon="exchange-alt" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
</template>
<script>
export default {
    props: ["instances"],
    methods: {
        handleDelete(inst) {
            this.$emit("delete-instance", inst);
        },
        handleRename(inst) {
          this.$emit("rename-instance", inst);
        }
    }
};
</script>
<style lang="scss">
.fa-exchange-alt {
  color: #dbdbdb;
}
.fa-exchange-alt:hover {
    color: rgba(10, 10, 10, 0.3);
}
</style>

