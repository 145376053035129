var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('h1',{staticClass:"title"},[_vm._v("General Information")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("General Contact Message For Help")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.gc.generalContactMessage),expression:"gc.generalContactMessage"}],staticClass:"textarea",domProps:{"value":(_vm.gc.generalContactMessage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.gc, "generalContactMessage", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('home-portal-middle',{staticClass:"mb-3",attrs:{"value":{ 'rowAccount': _vm.gc.rowAccount, 
                            'rowPmtDisposition': _vm.gc.rowPmtDisposition, 
                            'rowDue': _vm.gc.rowDue,
                            'rowPOTDue': _vm.gc.rowPOTDue,
                            'rowDemIdtyNo': _vm.gc.rowDemIdtyNo
                             }},on:{"home-portal-middle-updated":_vm.updateHomePortalMiddle}}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                             var errors = ref.errors;
                             var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("LogRocket App Id")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gc.logRocketAppId),expression:"gc.logRocketAppId"}],staticClass:"input",attrs:{"name":"logRocketAppId"},domProps:{"value":(_vm.gc.logRocketAppId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.gc, "logRocketAppId", $event.target.value)}}}),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.savePaymentPortalGlobalConfig}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])]),_c('progress-bar')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }