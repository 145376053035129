<template>
  <div class="control">
    <label v-if="controlLabel" class="label">{{ controlLabel }}</label>
    <div class="select">
      <select v-model="selectedSODCode">
        <option :value="null">{{ nullValueLabel }}</option>
        <option
          v-for="{ sodCode, sodName } in strategies"
          :key="sodCode"
          :value="sodCode"
        >
          {{ sodName }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String
    },
    nullValueLabel: {
      type: String,
      required: true
    },
    strategies: {
      type: Array,
      required: true
    },
    controlLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedSODCode: null
    };
  },
  $_veeValidate: {
    value() {
      return this.selectedSODCode;
    }
  },
  watch: {
    value(val) {
      this.selectedSODCode = val;
    },
    selectedSODCode(val) {
      this.$emit("input", val);
    }
  }
};
</script>
