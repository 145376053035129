<template>
  <div class="container">
    <div class="tabs is-boxed">
      <ul>
        <li :class="{ 'is-active': $route.name == 'makePayment' }">
          <router-link :to="{ name: 'makePayment' }">Make Payment</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'start' }">
          <router-link :to="{ name: 'start' }">Start</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'payLess' }">
          <router-link :to="{ name: 'payLess' }">Pay Less</router-link>
        </li>
        <li :class="{ 'is-active': $route.name == 'payLessAccepted' }">
          <router-link :to="{ name: 'payLessAccepted' }"
            >Pay Less Accepted</router-link
          >
        </li>
        <li :class="{ 'is-active': $route.name == 'payLessDeclined' }">
          <router-link :to="{ name: 'payLessDeclined' }"
            >Pay Less Declined</router-link
          >
        </li>
        <li :class="{ 'is-active': $route.name == 'payLessError' }">
          <router-link :to="{ name: 'payLessError' }"
            >Pay Less Error</router-link
          >
        </li>
      </ul>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        await vm.fetchPORSODStrategies();
        await vm.fetchPORTNGStrategies();
        await vm.fetchPaymentPortalInstanceConfig(to.params.id);
        await vm.fetchInstances();
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceNegotiator; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    ...mapActions("inst", ["fetchInstances"]),
    ...mapActions([
      "fetchPaymentPortalGlobalConfig",
      "fetchPORSODStrategies",
      "fetchPORTNGStrategies",
      "fetchPaymentPortalInstanceConfig",
      "saveInstanceConfig"
    ]),
    async save() {
      await this.saveInstanceConfig(this.ic);
      window.scrollTo(0, 0);
    },
    async revert() {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Instance config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="scss">
.clob {
  white-space: pre-wrap;
}
</style>
