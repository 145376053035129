import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Instances from "./views/instances/Instances.vue";
import Instance from "./views/instances/Instance.vue";
import InstanceMain from "./views/instances/instance/Main.vue";
import InstanceNegotiator from "./views/instances/instance/Negotiator.vue";
import InstanceDocuments from "./views/instances/instance/Documents.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/general",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/company",
      name: "company",
      component: () =>
        import(/* webpackChunkName: "company" */ "./views/Company.vue")
    },
    {
      path: "/users",
      name: "users",
      component: () =>
        import(/* webpackChunkName: "users" */ "./views/Users.vue")
    },
    {
      path: "/portal",
      name: "portal",
      component: () =>
        import(/* webpackChunkName: "portal" */ "./views/Portal.vue")
    },
    {
      path: "/hpp",
      name: "hpp",
      component: () =>
        import(/* webpackChunkName: "hpp" */ "./views/HostedPaymentPage.vue")
    },
    {
      path: "/secure-link",
      name: "secureLink",
      component: () =>
        import(/* webpackChunkName: "hpp" */ "./views/SecureLink.vue")
    },
    {
      path: "/receipt-comms",
      name: "receiptComms",
      component: () =>
          import(/* webpackChunkName: "hpp" */ "./views/ReceiptComms.vue")
    },
    {
      path: "/",
      name: "instances",
      component: Instances
    },
    {
      path: "/instances/:id",
      component: Instance,
      children: [
        {
          path: "",
          name: "instance.main",
          component: InstanceMain
        },
        {
          path: "login",
          name: "instance.login",
          component: () =>
            import("./views/instances/instance/Login.vue")
        },
        {
          path: "documents",
          name: "instance.documents",
          component: InstanceDocuments
        },
        {
          path: "overTime",
          name: "instance.overTime",
          component: () => 
            import("./views/instances/instance/OverTime.vue")
        },
        {
          path: "guest",
          name: "instance.guest",
          component:  () =>
          import("./views/instances/instance/Guest.vue")
        },
        {
          path: "theme",
          name: "instance.theme",
          component:  () =>
          import("./views/instances/instance/Theme.vue")
        },
        {
          path: "mfa",
          name: "instance.mfa",
          component:  () =>
          import("./views/instances/instance/MFA.vue")
        },
        {
          path: "negotiator",
          name: "instance.negotiator",
          component: InstanceNegotiator,
          children: [
            {
              path: "makePayment",
              name: "makePayment",
              component: () =>
                import("@/views/instances/instance/negotiator/MakePayment.vue")
            },
            {
              path: "payLess",
              name: "payLess",
              component: () =>
                import("@/views/instances/instance/negotiator/PayLess.vue")
            },
            {
              path: "payLessAccepted",
              name: "payLessAccepted",
              component: () =>
                import("@/views/instances/instance/negotiator/PayLessAccepted.vue")
            },
            {
              path: "payLessDeclined",
              name: "payLessDeclined",
              component: () =>
                import("@/views/instances/instance/negotiator/PayLessDeclined.vue")
            },
            {
              path: "start",
              name: "start",
              component: () =>
                import("@/views/instances/instance/negotiator/Start.vue")
            },
            {
              path: "payLessError",
              name: "payLessError",
              component: () =>
                import("@/views/instances/instance/negotiator/PayLessError.vue")
            }
          ]
        }
      ]
    }
  ]
});
