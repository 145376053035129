<template>
  <div class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div
            class="column is-centered is-5-tablet is-5-desktop is-5-widescreen"
          >
            <div class="box has-text-centered">
              <div class="field">
                <img src="../assets/css_logo_256x256.png" alt="CSS Logo" />
              </div>
              <div class="field">
                <h1 class="title is-4 has-text-black">CSS Web Admin</h1>
              </div>
              <p
                v-if="$route.query.sessionTimeout"
                class="notification is-warning"
              >
                Session Timed Out
              </p>
              <ValidationObserver tag="div" v-slot="{ invalid }" class="mb-3">
                <ValidationProvider
                  rules="required"
                  tag="div"
                  class="field"
                  v-slot="{ errors }"
                >
                  <div class="control has-icons-left">
                    <input
                      class="input"
                      type="text"
                      placeholder="Username"
                      v-model="username"
                      name="Username"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="user" />
                    </span>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  class="field"
                  v-slot="{ errors }"
                >
                  <div class="field">
                    <div class="control has-icons-left">
                      <input
                        class="input"
                        type="password"
                        placeholder="Password"
                        v-model="password"
                        name="Password"
                        @keyup.enter="login"
                      />
                      <span class="icon is-small is-left">
                        <font-awesome-icon icon="lock" />
                      </span>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </div>
                  <span v-if="errorFlag" class="has-text-danger">{{
                    errorMessage
                  }}</span>
                </ValidationProvider>
                <div class="field">
                  <div class="buttons is-centered">
                    <button
                      class="button is-link"
                      @click="login"
                      :disabled="invalid"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </ValidationObserver>
              <div class="field">
                <progress
                  class="progress is-small is-info"
                  max="100"
                  v-if="loadingFlag"
                  >15%</progress
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: `{_field_} is required`
});

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      username: null,
      password: null,
      loadingFlag: false,
      errorFlag: false,
      errorMessage: null
    };
  },
  methods: {
    async login() {
      try {
        this.loadingFlag = true;
        this.errorFlag = false;
        this.errorMessage = null;
        let body = "username=" + this.username + "&password=" + this.password;

        let { data } = await axios.post(
          process.env.VUE_APP_API_URL + "token",
          body,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        );

        await this.$store.dispatch("login", {
          jwt: data.token,
          username: this.username
        });

        this.$router.push({ name: "instances" });
      } catch (e) {
        this.errorFlag = true;
        this.errorMessage = "Invalid username or password";
      } finally {
        this.loadingFlag = false;
      }
    }
  }
};
</script>
