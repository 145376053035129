import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import strategiesModule from "./modules/strategies";
import instancesModule from "./modules/instances";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    strats: strategiesModule,
    inst: instancesModule
  },
  state: {
    userInfo: {
      jwt: null,
      username: null
    },
    notification: {
      show: false,
      message: null,
      type: null
    },
    progress: {
      show: false,
      value: 0
    },
    porregStrategies: [],
    porsodStrategies: [],
    portngStrategies: [],
    parrngStrategies: [],
    globalConfig: {},
    instanceConfig: {},
    hppConfig: {},
    secureLinkConfig: {},
    enableDocuments: null,
    receiptCommsConfig:{}
  },
  mutations: {
    SET_PARRNG_STRATEGIES: (state, parrngStrategies) => {
      state.parrngStrategies = parrngStrategies;
    },
    SET_PORREG_STRATEGIES: (state, porregStrategies) => {
      state.porregStrategies = porregStrategies;
    },
    SET_PORSOD_STRATEGIES: (state, porsodStrategies) => {
      state.porsodStrategies = porsodStrategies;
    },
    SET_PORTNG_STRATEGIES: (state, portngStrategies) => {
      state.portngStrategies = portngStrategies;
    },
    SET_GLOBAL_CONFIG: (state, globalConfig) => {
      state.globalConfig = globalConfig;
    },
    SET_HPP_CONFIG: (state, hppConfig) => {
      state.hppConfig = hppConfig;
    },
    SET_SECURE_LINK_CONFIG: (state, secureLinkConfig) => {
      state.secureLinkConfig = secureLinkConfig;
    },
    SET_INSTANCE_CONFIG: (state, instanceConfig) => {
      state.instanceConfig = instanceConfig;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo.jwt = userInfo.jwt;
      state.userInfo.username = userInfo.username;
    },
    SET_ENABLE_DOCUMENTS: (state, enableDocuments) => {
      state.enableDocuments = enableDocuments;
    },
    HIDE_NOTIFICATION: state => {
      state.notification.show = false;
      state.notification.message = null;
      state.notification.type = null;
    },
    SHOW_NOTIFICATION: (state, params) => {
      state.notification.show = true;
      state.notification.message = params.message;
      state.notification.type = params.type;
    },
    HIDE_PROGRESS: state => {
      state.progress.show = false;
    },
    SHOW_PROGRESS: state => {
      state.progress.show = true;
    },
    SET_PROGRESS_VALUE: (state, value) => {
      state.progress.value = value
    },
    SET_RECEIPT_COMMS_CONFIG: (state, receiptCommsConfig) => {
      state.receiptCommsConfig = receiptCommsConfig
    }
  },
  actions: {
    login({ commit }, userInfo) {
      commit("SET_USER_INFO", userInfo);
      localStorage.setItem("web-admin.jwt", userInfo.jwt);
      localStorage.setItem("web-admin.username", userInfo.username);
    },
    logout({ commit }) {
      let emptyInfo = { jwt: null, username: null };
      commit("SET_USER_INFO", emptyInfo);
      localStorage.removeItem("web-admin.jwt");
      localStorage.removeItem("web-admin.username");
    },
    fetchPaymentPortalGlobalConfig: async ({ commit, state }) => {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "api/configParameters/admin/paymentPortal",
        {
          headers: {
            Authorization: "Bearer " + state.userInfo.jwt
          }
        }
      );
      commit("SET_GLOBAL_CONFIG", data);
    },
    savePaymentPortalGlobalConfig: async ({ commit, state }, globalConfig) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "api/configParameters/admin/paymentPortal",
          globalConfig,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
        commit("SHOW_NOTIFICATION", {
          message: `Global config saved`,
          type: "success"
        });
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error saving global config; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    fetchPaymentPortalCompanyCodes: async ({ commit, state }) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/udw/paymentPortal/companyCodes;user=" +
            state.userInfo.username,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );

        return data;
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error fetching company codes; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    fetchPaymentPortalCompanyConfigs: async ({ commit, state }, cpyCode) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");

      try {
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/udw/paymentPortal/all;user=" +
            state.userInfo.username,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
        let filteredCompanyConfigs = data.filter(cc => cc.cpyCode == cpyCode);
        if (
          filteredCompanyConfigs != null &&
          filteredCompanyConfigs.length > 0
        ) {
          return filteredCompanyConfigs[0];
        } else {
          commit("SHOW_NOTIFICATION", {
            message: `Company config ${cpyCode} not found`,
            type: "error"
          });
        }
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error fetching company config; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    savePaymentPortalCompanyConfig: async ({ commit, state }, ppcc) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "api/udw/admin/paymentPortal",
          ppcc,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );

        commit("SHOW_NOTIFICATION", {
          message: `Company config saved`,
          type: "success"
        });
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error saving company config; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    uploadFile: async ({ commit, state }, file) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");

      let formData = new FormData();
      formData.append("uploadedFile", file);
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + "api/files/admin",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error uploading file; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    fetchUsers: async ({ commit, state }, searchFor) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL + "api/demographics/admin",
          {
            params: {
              searchFor: searchFor
            },
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
        return data;
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error fetching users; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    changePassword: async ({ commit, state }, pwChange) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            "api/demographics/admin/password;user=" +
            pwChange.user,
          pwChange,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
        commit("SHOW_NOTIFICATION", {
          message: `Password for ${pwChange.user} updated`,
          type: "success"
        });
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error changing password; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    fetchStrategies: async ({ state }, activityType) => {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "api/strategies",
        {
          params: {
            activityType: activityType
          },
          headers: {
            Authorization: "Bearer " + state.userInfo.jwt
          }
        }
      );

      return data;
    },
    fetchPORREGStrategies: async ({ dispatch, commit }) => {
      let strategies = await dispatch("fetchStrategies", "PORREG");
      commit("SET_PORREG_STRATEGIES", strategies);
    },
    fetchPORSODStrategies: async ({ dispatch, commit }) => {
      let strategies = await dispatch("fetchStrategies", "PORSOD");
      commit("SET_PORSOD_STRATEGIES", strategies);
    },
    fetchPORTNGStrategies: async ({ dispatch, commit }) => {
      let strategies = await dispatch("fetchStrategies", "PORTNG");
      commit("SET_PORTNG_STRATEGIES", strategies);
    },
    fetchPARRNGStrategies: async ({ dispatch, commit }) => {
      let strategies = await dispatch("fetchStrategies", "PARRNG");
      commit("SET_PARRNG_STRATEGIES", strategies);
    },

    fetchPaymentPortalInstanceConfig: async ({ commit, state }, id) => {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          `api/configParameters/admin/paymentPortal/${id}`,
        {
          headers: {
            Authorization: "Bearer " + state.userInfo.jwt
          }
        }
      );
      commit("SET_INSTANCE_CONFIG", data);
    },
    savePaymentPortalInstanceConfig: async (
      { commit, state },
      instanceConfig
    ) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            `api/configParameters/admin/paymentPortal/${instanceConfig.instanceId}`,
          instanceConfig,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
        commit("SHOW_NOTIFICATION", {
          message: `${instanceConfig.instanceId} config saved`,
          type: "success"
        });
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error saving instance config for ${instanceConfig.instanceId}; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    saveInstanceConfig: async ({ commit, state }, payload) => {
      commit("SHOW_PROGRESS");
      commit("HIDE_NOTIFICATION");
      try {
        await axios.post(
          process.env.VUE_APP_API_URL +
            `api/configParameters/admin/paymentPortal/${payload.instanceId}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
        );
        commit("SHOW_NOTIFICATION", {
          message: `${payload.instanceId} config saved`,
          type: "success"
        });
      } catch (e) {
        commit("SHOW_NOTIFICATION", {
          message: `Error saving instance config for ${payload.instanceId}; ${e}`,
          type: "error"
        });
      } finally {
        commit("HIDE_PROGRESS");
      }
    },
    fetchHostedPaymentPageConfig: async ({ commit }) => {
      let { data } = await axios.get(
          process.env.VUE_APP_API_URL_2 +
          "public/configParameters/hpp"
      );
      commit("SET_HPP_CONFIG", data);
    },
    saveHostedPaymentPageConfig: async ({ commit, state }, hppConfig) => {
      await axios.post(
          process.env.VUE_APP_API_URL +
          "api/configParameters/admin/hpp",
          hppConfig,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
      );
      commit("SET_HPP_CONFIG", hppConfig);
    },
    fetchSecureLinkConfig: async ({ commit, state }) => {
      let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
          "api/configParameters/admin/sec_link",
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
      );
      commit("SET_SECURE_LINK_CONFIG", data);
    },
    saveSecureLinkConfig: async ({ commit, state }, secureLinkConfig) => {
      await axios.post(
          process.env.VUE_APP_API_URL +
          "api/configParameters/admin/sec_link",
          secureLinkConfig,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
      );
      commit("SET_SECURE_LINK_CONFIG", secureLinkConfig);
    },
    fetchReceiptCommsConfig: async ({ commit, state }) => {
      let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
          "api/configParameters/admin/pmt_receipt",
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
      );
      commit("SET_RECEIPT_COMMS_CONFIG", data);
    },
    saveReceiptCommsConfig: async ({ commit, state }, receiptCommsConfig) => {
      await axios.post(
          process.env.VUE_APP_API_URL +
          "api/configParameters/admin/pmt_receipt",
          receiptCommsConfig,
          {
            headers: {
              Authorization: "Bearer " + state.userInfo.jwt
            }
          }
      );
      commit("SET_RECEIPT_COMMS_CONFIG", receiptCommsConfig);
    }
  },
  getters: {
    negotiatorEnabled: state => {
      if (state.globalConfig) {
        return state.globalConfig.negotiatorEnabled;
      }
      return false;
    },
    guestEnabled: state => {
      if( state.globalConfig ) {
        return state.globalConfig.guestEnabled;
      }
      return false;
    },
    payOverTimeEnabled: state => {
      if( state.globalConfig ) {
        return state.globalConfig.payOverTimeEnabled;
      }
      return false;
    }
  }
});
