const Functions = {
  arrayMatch(allData, criteriaArray) {
    let docTypeElement = null;
    let result = [];
    criteriaArray.forEach(element => {
      docTypeElement = allData.filter(item => item.type == element);
      docTypeElement.forEach(el => {
        result.push(el);
      });
    });
    return result;
  }
};
export default Functions;
