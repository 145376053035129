import axios from "axios";
import store from "./store/index";

export default {
  async authOk(jwt) {
    try {
      await axios.get(
        process.env.VUE_APP_API_URL +
          "api/configParameters/admin/paymentPortal",
        {
          headers: {
            Authorization: "Bearer " + jwt
          }
        }
      );
    } catch (e) {
      if (e != null && e.message.includes("403")) {
        return false;
      }
    }
    return true;
  },
  async getHealthReport() {
    let { data } = await axios.get(
      process.env.VUE_APP_API_URL + "api/portal/admin/health",
      {
        headers: {
          Authorization: "Bearer " + store.state.userInfo.jwt
        }
      }
    );
    return data;
  },
  async getDocTypes() {
    let { data } = await axios.get(
      process.env.VUE_APP_API_URL + "public/docTypes"
    );
    return data;
  },
  async loadConfig() {
    let { data } = await axios.get(
      process.env.VUE_APP_API_URL + "api/webLinks",
      {
        headers: {
          Authorization: "Bearer " + store.state.userInfo.jwt
        }
      }
    );
    return data;
  }
};
