import axios from "axios";

export default {
  namespaced: true,
  state: {
    strategies: {
      porovt: []
    }
  },
  mutations: {
    SET_STRATEGIES: (state, payload) => {
      if (payload.type == "POROVT") {
        state.strategies.porovt = payload.data;
      }
    }
  },
  actions: {
    fetchStrategies: async ({ rootState, commit }, activityType) => {
      let { data } = await axios.get(
        process.env.VUE_APP_API_URL + "api/strategies",
        {
          params: {
            activityType: activityType
          },
          headers: {
            Authorization: "Bearer " + rootState.userInfo.jwt
          }
        }
      );
      commit("SET_STRATEGIES", { type: activityType, data: data });
    }
  }
};
