<template>
  <instance-modal
      :show="show"
      title="Clone Instance"
      buttonLabel="Clone"
      @hide-modal="hideClone"
      @save-pressed="clone">
      <validation-provider class="field" v-slot="{ errors }" rules="required">
        <div class="control">
          <label class="label">Clone Instance</label>
          <div class="select">
            <select v-model="instance" name="Instance">
              <option :value="null">Please select an instance</option>
              <option v-for="instance in instances" :key="instance" :value="instance">{{ instance}}</option>
            </select>
          </div>
          <small
            class="has-text-danger"
            v-show="errors != null && errors.length > 0">
            {{ errors[0] }}
          </small>
        </div>
      </validation-provider>
      <validation-provider class="field" v-slot="{ errors }" rules="required|max:50">
        <div class="control">
          <label class="label">New Instance Name</label>
          <input type="text" class="input" v-model="newInstance" name="New Instance"/>
          <small
            class="has-text-danger"
            v-show="errors != null && errors.length > 0">
            {{ errors[0] }}
          </small>
        </div>
      </validation-provider>
  </instance-modal>
</template>
<script>
import { mapActions } from "vuex";
import InstanceModal from "./InstanceModal.vue";

export default {
  props: ["show","instances"],
  components: { InstanceModal },
  data() {
    return {
      instance: null,
      newInstance: null
    }
  },
  methods: {
    ...mapActions("inst", ["clonePaymentPortalInstanceConfig"]),
    hideClone() {
      this.instance = null;
      this.newInstance = null;
      this.$emit("show-updated", false);
    },
    async clone() {
      await this.clonePaymentPortalInstanceConfig({
        instanceId: this.instance,
        toId: this.newInstance
      });
      this.hideClone();
    }
  }
};
</script>