import { render, staticRenderFns } from "./CloneInstance.vue?vue&type=template&id=7b94bc7e&"
import script from "./CloneInstance.vue?vue&type=script&lang=js&"
export * from "./CloneInstance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports