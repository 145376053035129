<template>
  <div class="card field">
    <div class="card-header">
      <p class="card-header-title">{{ titleLabel }}</p>
    </div>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="control">
              <validation-provider
                tag="div"
                class="field"
                v-slot="{ errors, failed }"
                :rules="passedRules"
              >
                <div class="control">
                  <label class="label">{{ label1 }}</label>
                  <input
                    type="text"
                    class="input"
                    v-model="title"
                    :name="label1"
                  />
                </div>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <validation-provider
                tag="div"
                class="field"
                v-slot="{ errors, failed }"
                :rules="passedRules"
              >
                <div class="control">
                  <label class="label">{{ label2 }}</label>
                  <input
                    type="text"
                    class="input"
                    v-model="content"
                    :name="label2"
                  />
                </div>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <validation-provider
                tag="div"
                class="field"
                v-slot="{ errors, failed }"
                :rules="passedRules"
              >
                <div class="control">
                  <label class="label">{{ label3 }}</label>
                  <input
                    type="text"
                    class="input"
                    v-model="button"
                    :name="label3"
                  />
                </div>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="column">
          <img :src="getImage.call()" :alt="imgAlt" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required."
});

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    ValidationProvider
  },
  props: {
    p1: String,
    p2: String,
    p3: String,
    label1: String,
    label2: String,
    label3: String,
    titleLabel: String,
    passedRules: Object,
    emitName: String,
    imgSrc: String,
    imgAlt: String
  },
  data() {
    return {
      title: null,
      content: null,
      button: null
    };
  },
  computed: {
    getImage() {
      let obj = {};
      if (this.imgSrc == "@/assets/pay_less_guide.png") {
        obj.call = () => require("@/assets/pay_less_guide.png");
      } else if (this.imgSrc == "@/assets/over_time_guide.png") {
        obj.call = () => require("@/assets/over_time_guide.png");
      } else if (this.imgSrc == "@/assets/negotiator_option_guide.png") {
        obj.call = () => require("@/assets/negotiator_option_guide.png");
      } else if (this.imgSrc == "@/assets/ppay_option_guide.png") {
        obj.call = () => require("@/assets/ppay_option_guide.png");
      } else if (this.imgSrc == "@/assets/ppay_option_one_guide.png") {
        obj.call = () => require("@/assets/ppay_option_one_guide.png");
      } else if (this.imgSrc == "@/assets/ppay_option_upd_guide.png") {
        obj.call = () => require("@/assets/ppay_option_upd_guide.png");
      }
      return obj;
      //return "@/assets/pay_less_guide.png"
    }
  },
  methods: {
    emitUpdate() {
      this.$emit(this.emitName == null ? "tempEvent" : this.emitName, {
        title: this.title,
        content: this.content,
        button: this.button
      });
    }
  },
  watch: {
    p1(nv) {
      this.title = nv;
    },
    p2(nv) {
      this.content = nv;
    },
    p3(nv) {
      this.button = nv;
    },
    title() {
      this.emitUpdate();
    },
    content() {
      this.emitUpdate();
    },
    button() {
      this.emitUpdate();
    }
  }
};
//"custom-pay-option-updated"
</script>
