<template>
  <div class="card field">
    <div class="card-header">
      <p class="card-header-title">Home and Header Customizations</p>
    </div>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="control">
              <validation-provider
                tag="div"
                class="field"
                v-slot="{ errors, failed }"
                rules="required|max:255"
              >
                <div class="control">
                  <label class="label">Column 1*</label>
                  <input
                    type="text"
                    class="input"
                    v-model="hc1"
                    name="Home Column 1"
                  />
                </div>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <validation-provider
                tag="div"
                class="field"
                v-slot="{ errors, failed }"
                rules="required|max:255"
              >
                <div class="control">
                  <label class="label">Column 2*</label>
                  <input
                    type="text"
                    class="input"
                    v-model="hc2"
                    name="Home Column 2"
                  />
                </div>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <validation-provider
                tag="div"
                class="field"
                v-slot="{ errors, failed }"
                rules="required|max:255"
              >
                <div class="control">
                  <label class="label">Column 3*</label>
                  <input
                    type="text"
                    class="input"
                    v-model="hc3"
                    name="Home Column 3"
                  />
                </div>
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="column">
          <img
            src="../../assets/ppay_home_cols.png"
            alt="Home And Header Columns Specification"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required."
});

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    ValidationProvider
  },
  props: ["homeCol1", "homeCol2", "homeCol3"],
  data() {
    return {
      hc1: null,
      hc2: null,
      hc3: null
    };
  },
  methods: {
    emitHomeAndHeaderCustomizationsUpdated() {
      this.$emit("home-and-header-customizations-updated", {
        hc1: this.hc1,
        hc2: this.hc2,
        hc3: this.hc3
      });
    }
  },
  watch: {
    homeCol1(nv) {
      this.hc1 = nv;
    },
    homeCol2(nv) {
      this.hc2 = nv;
    },
    homeCol3(nv) {
      this.hc3 = nv;
    },
    hc1() {
      this.emitHomeAndHeaderCustomizationsUpdated();
    },
    hc2() {
      this.emitHomeAndHeaderCustomizationsUpdated();
    },
    hc3() {
      this.emitHomeAndHeaderCustomizationsUpdated();
    }
  }
};
</script>
