<template>
  <div class="modal" :class="{ 'is-active': show }" v-if="show">
    <div class="modal-background"></div>
    <validation-observer class="modal-card" v-slot="{ invalid }">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title  }}</p>
        <button class="delete" aria-label="close" @click="$emit('hide-modal')"></button>
      </header>
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="modal-card-foot">
        <button
            class="button is-success"
            @click="$emit('save-pressed')"
            :disabled="progress.show || invalid"
        >
          {{ buttonLabel }}
        </button>
        <button class="button" @click="$emit('hide-modal')" :disabled="progress.show">
          Cancel
        </button>
        <progress class="progress is-info" v-if="progress.show" />
      </footer>
    </validation-observer>
  </div>
</template>

<script>
import OpMixin from "./InstanceOpMixin";

export default {
  name: "InstanceModal",
  props: ["show","title","buttonLabel"],
  mixins: [OpMixin]
}
</script>

<style scoped>

</style>