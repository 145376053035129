import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    instances: []
  },
  mutations: {
    SET_INSTANCES: (state, instances) => (state.instances = instances),
    ADD_INSTANCE: (state, instance) => state.instances.push(instance),
    RENAME_INSTANCE: (state, {instance, toId}) => {
      const idx = state.instances.findIndex( inst => inst == instance );
      if( idx != -1 ) {
        Vue.set(state.instances, idx, toId);
      }
    }
  },
  actions: {
    fetchInstances: async ({ commit, rootState }) => {
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/configParameters/admin/paymentPortalInstances",
          {
            headers: {
              Authorization: "Bearer " + rootState.userInfo.jwt
            }
          }
        );
        commit("SET_INSTANCES", data);
      },
      addPaymentPortalInstanceConfig: async ({ commit, state, rootState }, instanceId) => {
        commit("SHOW_PROGRESS", null, { root: true });
        commit("HIDE_NOTIFICATION", null, { root: true });
  
        if( state.instances.includes(instanceId) ) {
          commit("SHOW_NOTIFICATION", {
            message: `A config for ${instanceId} already exists`,
            type: "error"
          }, { root: true });
          return;
        }
  
        try {
          await axios.post(
            process.env.VUE_APP_API_URL +
              `api/configParameters/admin/paymentPortal/${instanceId}/add`,
            {},
            {
              headers: {
                Authorization: "Bearer " + rootState.userInfo.jwt
              }
            }
          );
          commit("ADD_INSTANCE", instanceId);
          commit("SHOW_NOTIFICATION", {
            message: `${instanceId} config saved`,
            type: "success"
          }, { root: true });
        } catch (e) {
          commit("SHOW_NOTIFICATION", {
            message: `Error adding instance config for ${instanceId}; ${e}`,
            type: "error"
          });
        } finally {
          commit("HIDE_PROGRESS", null, { root: true });
        }
      },
      clonePaymentPortalInstanceConfig: async ({ commit, state, rootState }, {instanceId, toId}) => {
        commit("SHOW_PROGRESS", null, { root: true });
        commit("HIDE_NOTIFICATION", null, { root: true });
  
        if( state.instances.includes(toId) ) {
          commit("SHOW_NOTIFICATION", {
            message: `A config for ${toId} already exists`,
            type: "error"
          }, { root: true });
          return;
        }
  
        try {
          await axios.post(
            process.env.VUE_APP_API_URL +
              `api/configParameters/admin/paymentPortal/${instanceId}/clone;toId=${toId}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + rootState.userInfo.jwt
              }
            }
          );
          commit("ADD_INSTANCE", toId);
          commit("SHOW_NOTIFICATION", {
            message: `${toId} config cloned`,
            type: "success"
          }, { root: true });
        } catch (e) {
          commit("SHOW_NOTIFICATION", {
            message: `Error cloning instance config based on ${instanceId}; ${e}`,
            type: "error"
          }, { root: true });
        } finally {
          commit("HIDE_PROGRESS", null, { root: true });
        }
      },
      deletePaymentPortalInstanceConfig: async (
        { commit, rootState, dispatch },
        instanceId
      ) => {
        commit("SHOW_PROGRESS", null, { root: true });
        commit("HIDE_NOTIFICATION", null, { root: true });
        try {
          commit("SET_PROGRESS_VALUE", 10, { root: true })
          await axios.delete(
            process.env.VUE_APP_API_URL +
              `api/configParameters/admin/paymentPortal/${instanceId}`,
            {
              headers: {
                Authorization: "Bearer " + rootState.userInfo.jwt
              }
            }
          );
          commit("SET_PROGRESS_VALUE", 50, { root: true })
          dispatch("fetchInstances", null);
          commit("SET_PROGRESS_VALUE", 99, { root: true });
          commit("SHOW_NOTIFICATION", {
            message: `${instanceId} config deleted`,
            type: "success"
          }, { root: true });
        } catch (e) {
          commit("SHOW_NOTIFICATION", {
            message: `Error deleting instance config for ${instanceId}; ${e}`,
            type: "error"
          }, { root: true });
        } finally {
          commit("HIDE_PROGRESS", null, { root: true });
          commit("SET_PROGRESS_VALUE", 0, { root: true });
        }
      },
      renamePaymentPortalInstanceConfig : async (
        { commit, state, rootState },
        { instanceId, toId }
      ) => {
        commit("SHOW_PROGRESS", null, { root: true });
        commit("HIDE_NOTIFICATION", null, { root: true });
  
        if( state.instances.includes(toId) ) {
          commit("SHOW_NOTIFICATION", {
            message: `A config for ${toId} already exists`,
            type: "error"
          },
              { root: true });
          return;
        }
  
        try {
          await axios.post(
            process.env.VUE_APP_API_URL +
              `api/configParameters/admin/paymentPortal/${instanceId}/rename;toId=${toId}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + rootState.userInfo.jwt
              }
            }
          );
          commit("RENAME_INSTANCE", {instance: instanceId, toId: toId});
          commit("SHOW_NOTIFICATION", {
            message: `${instanceId} renamed to ${toId}`,
            type: "success"
          }, {root: true});
        } catch (e) {
          commit("SHOW_NOTIFICATION", {
            message: `Error renaming instance config ${instanceId} to ${toId}; ${e}`,
            type: "error"
          }, { root: true });
        } finally {
          commit("HIDE_PROGRESS", null, { root: true });
        }
      }
  }
};
