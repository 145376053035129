import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSyncAlt,
  faArrowAltCircleRight,
  faDollarSign,
  faLevelUpAlt,
  faLevelDownAlt,
  faReply,
  faLock,
  faUser,
  faUpload,
  faPencilAlt,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faExchangeAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import api from "@/Service.js";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, max, min } from "vee-validate/dist/rules";

library.add(faSyncAlt);
library.add(faArrowAltCircleRight);
library.add(faDollarSign);
library.add(faLevelUpAlt);
library.add(faLevelDownAlt);
library.add(faReply);
library.add(faLock);
library.add(faUser);
library.add(faUpload);
library.add(faPencilAlt);
library.add(faAngleDoubleRight);
library.add(faAngleDoubleLeft);
library.add(faExchangeAlt);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  if (to.name == "login") {
    next(); // always ok to go straight to login page
  } else {
    if (store.state.jwt) {
      let retval = await api.authOk(store.state.userInfo.jwt);
      if (retval) {
        next();
      } else {
        next({ name: "login", query: { sessionTimeout: true } });
      }
    } else {
      let savedJWT = localStorage.getItem("web-admin.jwt");
      let savedUsername = localStorage.getItem("web-admin.username");
      if (savedJWT && savedUsername) {
        store.commit("SET_USER_INFO", {
          jwt: savedJWT,
          username: savedUsername
        });
        let retval = await api.authOk(store.state.userInfo.jwt);
        if (retval) {
          next();
        } else {
          next({ name: "login", query: { sessionTimeout: true } });
        }
      } else {
        next({ name: "login" });
      }
    }
  }
});

extend("required", {
  ...required,
  message: "{_field_} is required."
});

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

extend("min", {
  ...min,
  params: ["length"],
  message: "{_field_} must have at least {length} characters."
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
