<template>
  <div class="field">
    <div class="file is-light">
      <label class="file-label">
        <input
          class="file-input"
          type="file"
          :name="inputName"
          :ref="inputRef"
          @change="uploadFile"
        />
        <span class="file-cta">
          <span class="file-icon">
            <font-awesome-icon icon="upload" />
          </span>
          <span class="file-label">
            Choose a file…
          </span>
        </span>
        <span v-if="file != null" class="file-name">
          {{ file.name }}
        </span>
        <span v-else class="file-name">
          No file selected
        </span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: ["inputName", "inputRef"],
  data() {
    return {
      file: null
    };
  },
  methods: {
    async uploadFile() {
      this.file = this.$refs[this.inputRef].files[0];
      await this.$store.dispatch("uploadFile", this.file);
      this.$emit("fileUploaded", this.computeImagePath(this.file.name));
      this.file = null;
    },
    computeImagePath: fn => {
      if (process.env.NODE_ENV === "production") {
        return `${document.location.origin}${process.env.VUE_APP_API_URL}public/files/${fn}`;
      } else {
        return `${process.env.VUE_APP_API_URL}public/files/${fn}`;
      }
    }
  }
};
</script>
