<template>
  <instance-modal
      :show="show"
      title="Add Instance"
      buttonLabel="Add"
      @hide-modal="hideAdd"
      @save-pressed="add">
    <validation-provider class="field" rules="required|max:50" v-slot="{ errors }">
      <div class="control">
        <label class="label">Instance Name</label>
        <input type="text" class="input" name="Instance Name" v-model="newInstance" />
        <small
          class="has-text-danger"
          v-show="errors != null && errors.length > 0">
          {{ errors[0] }}
        </small>
      </div>
    </validation-provider>
  </instance-modal>
</template>
<script>
import { mapActions } from "vuex";
import InstanceModal from "./InstanceModal";

export default {
  props: ["show"],
  components: { InstanceModal },
  data() {
    return {
      newInstance: null
    };
  },
  methods: {
    ...mapActions("inst", ["addPaymentPortalInstanceConfig"]),
    hideAdd() {
      this.newInstance = null;
      this.$emit("show-updated", false);
    },
    async add() {
        await this.addPaymentPortalInstanceConfig(this.newInstance);
        this.hideAdd();
    }
  }
};
</script>
