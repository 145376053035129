<template>
    <div>
        <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="required|max:255"
        >
            <div class="control">
                <label class="label">Required Row ACCOUNT Label</label>
                <input
                type="text"
                class="input"
                placeholder="e.g. ACCOUNT"
                v-model="rowAccount"
                name="Row ACCOUNT"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </div>
        </validation-provider>
        <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="required|max:255"
        >
            <div class="control">
                <label class="label">Required Row PMT_DISPOSITION Label</label>
                <input
                type="text"
                class="input"
                placeholder="e.g. PMT_DISPOSITION"
                v-model="rowPmtDisposition"
                name="Row PMT_DISPOSITION"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </div>
        </validation-provider>
        <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="required|max:255"
        >
            <div class="control">
                <label class="label">Required Row DUE Label</label>
                <input
                type="text"
                class="input"
                placeholder="e.g. DUE"
                v-model="rowDue"
                name="Row DUE"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </div>
        </validation-provider>
      <validation-provider
          tag="div"
          class="field"
          v-slot="{ errors, failed }"
          rules="required|max:255"
      >
        <div class="control">
          <label class="label">Required Row Pay Over Time DUE Label</label>
          <input
              type="text"
              class="input"
              placeholder="e.g. POT_DUE"
              v-model="rowPOTDue"
              name="Row Pay Over Time DUE"
          />
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </div>
      </validation-provider>
        <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="required|max:255"
        >
            <div class="control">
                <label class="label">Required Row DEMIDTYNO Label</label>
                <input
                type="text"
                class="input"
                placeholder="e.g. DEMIDTYNO"
                v-model="rowDemIdtyNo"
                name="Row DEMIDTYNO"
                />
                <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
            </div>
        </validation-provider>
    </div>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {
            rowAccount: null,
            rowPmtDisposition: null,
            rowDue: null,
            rowPOTDue: null,
            rowDemIdtyNo: null
        };
    },
    methods: {
        postUpdate() {
           this.$emit("home-portal-middle-updated", {
                rowAccount: this.rowAccount,
                rowPmtDisposition: this.rowPmtDisposition,
                rowDue: this.rowDue,
                rowPOTDue: this.rowPOTDue,
                rowDemIdtyNo: this.rowDemIdtyNo
            });
        }
    },
    watch: {
        value(val) {
            this.rowAccount = val.rowAccount;
            this.rowPmtDisposition = val.rowPmtDisposition;
            this.rowDue = val.rowDue;
            this.rowPOTDue = val.rowPOTDue;
            this.rowDemIdtyNo = val.rowDemIdtyNo;
        },
        rowAccount() { this.postUpdate(); },
        rowPmtDisposition() { this.postUpdate(); },
        rowDue() { this.postUpdate(); },
        rowPOTDue() { this.postUpdate(); },
        rowDemIdtyNo() { this.postUpdate(); }
    }
};
</script>
