import { mapActions } from "vuex";

const saveRevert = {
  data() {
    return {
      negotiatorRuleObj: { required: true, max: { length: 255 } },
      ic: {
        systemErrorTitle: null,
        systemErrorContent: null
      }
    };
  },
  methods: {
    ...mapActions("inst", ["fetchInstances"]),
    ...mapActions([
      "fetchPaymentPortalInstanceConfig",
      "saveInstanceConfig",
      "fetchPaymentPortalGlobalConfig",
      "fetchPORSODStrategies",
      "fetchPORTNGStrategies",
      "fetchPARRNGStrategies"
    ]),
    async save() {
      await this.saveInstanceConfig(this.ic);
    },
    async revert() {
      await this.fetchPaymentPortalInstanceConfig(this.$route.params.id);
      this.loadView();
    }
  }
};

export default saveRevert;
