var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card field"},[_vm._m(0),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Column 1*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hc1),expression:"hc1"}],staticClass:"input",attrs:{"type":"text","name":"Home Column 1"},domProps:{"value":(_vm.hc1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.hc1=$event.target.value}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Column 2*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hc2),expression:"hc2"}],staticClass:"input",attrs:{"type":"text","name":"Home Column 2"},domProps:{"value":(_vm.hc2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.hc2=$event.target.value}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Column 3*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hc3),expression:"hc3"}],staticClass:"input",attrs:{"type":"text","name":"Home Column 3"},domProps:{"value":(_vm.hc3)},on:{"input":function($event){if($event.target.composing){ return; }_vm.hc3=$event.target.value}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)])]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Home and Header Customizations")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("../../assets/ppay_home_cols.png"),"alt":"Home And Header Columns Specification"}})])}]

export { render, staticRenderFns }